<template>
  <div
    class="advertisement-content"
    :style="{ width: isshow ? 'auto' : '100%' }"
  >
    <!--广告收起-->
    <div class="Putaway" v-if="isshow">
      <img src="../assets/img/Putaway.png" class="Putawayimg" />
      <img
        src="../assets/img/chevron_right_small.png"
        class="icon_right"
        @click="isshow = !isshow"
      />
    </div>
    <!--广告出现-->
    <div class="open" v-if="!isshow">
      <!--广告收起-->
      <div class="wrapbg">
        <div class="bannersclose">
          <img src="../assets/img/fengye.png" class="fengye" />
          <div class="bannerswrap">
            <img src="../assets/img/phone.png" class="phone" />
            <div class="words">
              <p class="wordtitle">新人首日0元租+千元礼包</p>
              <p class="wordtext">下载枫叶租车app并注册即可获得</p>
            </div>
            <div class="codeerwei">
              <img
                src="https://testfy2019.oss-cn-qingdao.aliyuncs.com/pcadmin/b5D18eckOM3Rka2p3WuBlsgVn2vT4gdQXzThx16d.png"
              />
              <div class="codeword">
                <p class="codes">扫码下载</p>
                <p class="codes">枫叶租车APP</p>
                <div class="hengborder"></div>
                <p class="fuliword">更多福利提前知</p>
              </div>
            </div>
          </div>
          <img
            src="../assets/img/close_circle.png"
            class="closeimg"
            @click="isshow = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {}, //添加注册组件事件
  data() {
    return {
      isshow: true,
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.wrapbg {
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.7);
  backdrop-filter: blur(15px);
}
.advertisement-content {
  width: auto;
  position: fixed;
  bottom: 0;
  z-index: 999;
}
.bannersclose {
  position: relative;
  width: 100%;
  height: 206px;
}
.bannersclose .fengye {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.bannerswrap .phone {
  display: block;
    width: 157px;
    height: 264px;
    position: absolute;
    z-index: 9;
    left: -4%;
    bottom: 0%;
}
.bannersclose .bannerswrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.bannersclose .bannerswrap .words {
  padding-left: 16%;
}
.bannersclose .bannerswrap .words .wordtitle {
  background: linear-gradient(180deg, #ffe3ba, #ddb98d);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "HYLeMiaoTi";
}
.bannersclose .bannerswrap .words .wordtext {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 32px;
}
.bannersclose .bannerswrap .codeerwei {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bannersclose .bannerswrap .codeerwei img {
  width: 165px;
  height: 165px;
  margin-right: 30px;
}
.bannersclose .bannerswrap .bannermobile {
  position: absolute;
  left: 0;
  top: 0;
}
.bannersclose .bannerswrap .codeerwei .codeword .codes {
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 35px;
}
.bannersclose .bannerswrap .codeerwei .codeword .hengborder {
  width: 31px;
  height: 1px;
  background: #ffffff;
  margin: 10px 0;
}
.bannersclose .bannerswrap .codeerwei .codeword .fuliword {
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  color: #e1be92;
  line-height: 35px;
}
.bannersclose .bannerswrap {
  width: 75%;
  margin: 0 auto;
  height: 100%;
}
.bannersclose .bannerswrap .advertisement-content {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.Putaway {
  position: relative;
  z-index: 999;
  height: 206px;
}
.Putaway .Putawayimg {
  height: 206px;
}
.Putaway .icon_right {
  position: absolute;
  left: 200px;
  top: 45%;
  width: 22px;
  height: 36px;
  cursor: pointer;
}
.open {
  width: 100%;
  position: relative;
  z-index: 999;
}
.open .openimg {
  width: 100%;
  height: 100%;
}
.open .closeimg {
  position: absolute;
  right: 3%;
  top: 15%;
  width: 44px;
  height: 44px;
  cursor: pointer;
}
@font-face {
    font-family: "HYLeMiaoTi";        
    src:url("../assets/img/YouSheBiaoTiHei-2.ttf");      
    font-weight: normal;
    font-style: normal;
}
@media screen and (max-width: 1600px) {
  .Putaway .Putawayimg {
    height: 150px;
  }
  .Putaway .icon_right {
    width: 16px;
    height: 24px;
    left: 148px;
  }
  .open {
    height: 150px;
  }
  .bannersclose,.Putaway{
  height: 150px;
}
  .bannerswrap .phone{
    height: 200px;
    width: auto;
  }
  .bannersclose .bannerswrap .words .wordtitle{
    font-size: 32px;
  }
  .bannersclose .bannerswrap .words .wordtext{
    font-size: 18px;
  }
  .bannersclose .bannerswrap .codeerwei img{
    width: 100px;
    height: 100px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .codes{
    font-size: 18px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .fuliword{
    font-size: 18px;
  }
  .open .closeimg{
    width:30px;
    height: 30px;
  }
}
@media screen and (max-width: 1440px) {
  .Putaway .Putawayimg {
    height: 150px;
  }
  .Putaway .icon_right {
    width: 16px;
    height: 24px;
  }
.open {
    height: 150px;
  }
   .bannersclose,.Putaway{
  height: 150px;
}
  .bannerswrap .phone{
    height: 200px;
    width: auto;
  }
  .bannersclose .bannerswrap .words .wordtitle{
    font-size: 24px;
  }
  .bannersclose .bannerswrap .words .wordtext{
    font-size: 16px;
  }
  .bannersclose .bannerswrap .codeerwei img{
    width: 100px;
    height: 100px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .codes{
    font-size: 16px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .fuliword{
    font-size: 16px;
  }
  .open .closeimg{
    width:30px;
    height: 30px;
  }
}
@media screen and (max-width: 1366px) {
  .Putaway .Putawayimg {
    height: 150px;
  }
  .Putaway .icon_right {
    width: 16px;
    height: 24px;
  }
  .open {
    height: 150px;
  }
   .bannersclose,.Putaway{
  height: 150px;
}
  .bannerswrap .phone{
    height: 200px;
    width: auto;
  }
  .bannersclose .bannerswrap .words .wordtitle{
    font-size: 24px;
  }
  .bannersclose .bannerswrap .words .wordtext{
    font-size: 16px;
  }
  .bannersclose .bannerswrap .codeerwei img{
    width: 100px;
    height: 100px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .codes{
    font-size: 16px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .fuliword{
    font-size: 16px;
  }
  .open .closeimg{
    width:30px;
    height: 30px;
  }
}
@media screen and (max-width: 1280px) {
  .Putaway .Putawayimg {
    height: 150px;
  }
  .Putaway .icon_right {
    width: 16px;
    height: 24px;
  }
  .open {
    height: 150px;
  }
   .bannersclose,.Putaway{
  height: 150px;
}
  .bannerswrap .phone{
    height: 200px;
    width: auto;
  }
  .bannersclose .bannerswrap .words .wordtitle{
    font-size: 24px;
  }
  .bannersclose .bannerswrap .words .wordtext{
    font-size: 16px;
  }
  .bannersclose .bannerswrap .codeerwei img{
    width: 100px;
    height: 100px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .codes{
    font-size: 16px;
  }
  .bannersclose .bannerswrap .codeerwei .codeword .fuliword{
    font-size: 16px;
  }
  .open .closeimg{
    width:30px;
    height: 30px;
  }
}
</style>
